// @file Plans store
import { isAppUsing } from '@@/bits/flip'
import { SubscriptionFilter } from '@@/bits/landside_helper'
import type { PlanPricing, SubscriptionPlan } from '@@/bits/plans_helper'
import {
  SubscriptionType,
  accountSetupBusinessSubscriptions,
  accountSetupPersonalSubscriptions,
  accountSetupStudentSubscriptions,
  accountSetupTeacherAndStaffSubscriptions,
  businessSubscriptions,
  educationSubscriptions,
  freeSubscription,
  homeSubscriptions,
  libraryCreateClassroomSubscriptions,
  libraryCreateTeamSubscriptions,
  updateSubscriptionsWithPrice,
} from '@@/bits/plans_helper'
import { PricingPlansExperimentVariant } from '@@/enums'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const usePlansStore = defineStore('plans', () => {
  const homePlanPrices = ref<PlanPricing[]>([])
  const businessPlanPrices = ref<PlanPricing[]>([])
  const classroomPlanPrices = ref<PlanPricing[]>([])
  const schoolPlanPrices = ref<PlanPricing[]>([])
  const currencySign = ref('')
  const schoolCurrencySign = ref('$') // Hardcoded default value

  // Getters

  const freePlans = computed(() => freeSubscription)

  const homePlans = computed((): SubscriptionPlan[] => {
    return isAppUsing('personalPricingPlansExperimentV2')
      ? updateSubscriptionsWithPrice(homeSubscriptions, homePlanPrices.value, currencySign.value)
      : updateSubscriptionsWithPrice(homeSubscriptions, homePlanPrices.value, currencySign.value).filter(
          (plan) => plan.subType !== SubscriptionType.PersonalSilver,
        )
  })

  const businessPlans = computed((): SubscriptionPlan[] =>
    updateSubscriptionsWithPrice(businessSubscriptions, businessPlanPrices.value, currencySign.value),
  )

  const educationPlans = computed((): SubscriptionPlan[] => {
    const plans = educationSubscriptions

    const classroomPlan = plans.filter((plan) => plan.subType === SubscriptionType.Classroom)
    const updatedClassroomPlan = updateSubscriptionsWithPrice(
      classroomPlan,
      classroomPlanPrices.value,
      currencySign.value,
    )
    const schoolPlan = plans.filter((plan) => plan.subType === SubscriptionType.School)
    const updatedSchoolPlan = updateSubscriptionsWithPrice(schoolPlan, schoolPlanPrices.value, schoolCurrencySign.value)
    return [...updatedClassroomPlan, ...updatedSchoolPlan]
  })

  const libraryCreateClassroomPlans = computed((): SubscriptionPlan[] =>
    updateSubscriptionsWithPrice(libraryCreateClassroomSubscriptions, classroomPlanPrices.value, currencySign.value),
  )
  const libraryCreateTeamPlans = computed((): SubscriptionPlan[] =>
    updateSubscriptionsWithPrice(libraryCreateTeamSubscriptions, businessPlanPrices.value, currencySign.value),
  )

  const accountSetupPersonalPlans = computed((): SubscriptionPlan[] => {
    const freePlan = accountSetupPersonalSubscriptions.find(
      (plan) => plan.subType === SubscriptionType.Free,
    ) as SubscriptionPlan
    const homePlans = accountSetupPersonalSubscriptions.filter((plan) => plan.filterType === SubscriptionFilter.HOME)
    const businessPlans = accountSetupPersonalSubscriptions.filter(
      (plan) => plan.filterType === SubscriptionFilter.BUSINESS,
    )

    const updatedHomePlans = updateSubscriptionsWithPrice(homePlans, homePlanPrices.value, currencySign.value)
    const updatedBusinessPlans = updateSubscriptionsWithPrice(
      businessPlans,
      businessPlanPrices.value,
      currencySign.value,
    )

    return [freePlan, ...updatedHomePlans, ...updatedBusinessPlans]
  })

  const accountSetupStudentPlans = computed((): SubscriptionPlan[] => {
    return updateSubscriptionsWithPrice(accountSetupStudentSubscriptions, homePlanPrices.value, currencySign.value)
  })

  const accountSetupTeacherAndStaffPlans = computed((): SubscriptionPlan[] => {
    const freePlan = accountSetupTeacherAndStaffSubscriptions.find(
      (plan) => plan.subType === SubscriptionType.Free,
    ) as SubscriptionPlan
    const homePlans = accountSetupTeacherAndStaffSubscriptions.filter(
      (plan) => plan.filterType === SubscriptionFilter.HOME,
    )
    const classroomPlans = accountSetupTeacherAndStaffSubscriptions.filter(
      (plan) => plan.subType === SubscriptionType.Classroom,
    )
    const businessPlans = accountSetupTeacherAndStaffSubscriptions.filter(
      (plan) => plan.filterType === SubscriptionFilter.BUSINESS,
    )

    const updatedHomePlans = updateSubscriptionsWithPrice(homePlans, homePlanPrices.value, currencySign.value)
    const updatedClassroomPlans = updateSubscriptionsWithPrice(
      classroomPlans,
      classroomPlanPrices.value,
      currencySign.value,
    )
    const updatedBusinessPlans = updateSubscriptionsWithPrice(
      businessPlans,
      businessPlanPrices.value,
      currencySign.value,
    )

    return [freePlan, ...updatedHomePlans, ...updatedClassroomPlans, ...updatedBusinessPlans]
  })

  const accountSetupBusinessPlans = computed((): SubscriptionPlan[] => {
    const freePlan = accountSetupBusinessSubscriptions.find(
      (plan) => plan.subType === SubscriptionType.Free,
    ) as SubscriptionPlan
    const homePlans = accountSetupBusinessSubscriptions.filter((plan) => plan.filterType === SubscriptionFilter.HOME)
    const businessPlans = accountSetupBusinessSubscriptions.filter(
      (plan) => plan.filterType === SubscriptionFilter.BUSINESS,
    )
    const updatedHomePlans = updateSubscriptionsWithPrice(homePlans, homePlanPrices.value, currencySign.value)
    const updatedBusinessPlans = updateSubscriptionsWithPrice(
      businessPlans,
      businessPlanPrices.value,
      currencySign.value,
    )

    return [freePlan, ...updatedHomePlans, ...updatedBusinessPlans]
  })

  // Actions

  function initializeState(data: {
    homePlans: PlanPricing[]
    teamPlans: PlanPricing[]
    classroomPlans: PlanPricing[]
    schoolPlans: PlanPricing[]
    currencySign: string
    schoolCurrencySign: string
  }): void {
    currencySign.value = data.currencySign ?? currencySign.value
    schoolCurrencySign.value = data.schoolCurrencySign ?? schoolCurrencySign.value
    homePlanPrices.value = data.homePlans ?? homePlanPrices.value
    businessPlanPrices.value = data.teamPlans ?? businessPlanPrices.value
    classroomPlanPrices.value = data.classroomPlans ?? classroomPlanPrices.value
    schoolPlanPrices.value = data.schoolPlans ?? schoolPlanPrices.value
  }

  function getHomePlansByVariant(variant: PricingPlansExperimentVariant): SubscriptionPlan[] {
    const plans: SubscriptionPlan[] = []
    homePlans.value.forEach((plan) => {
      // Don't return Personal Silver plan if the variant is not PlatinumSilverAnnualOnly
      if (
        variant !== PricingPlansExperimentVariant.PlatinumSilverAnnualOnly &&
        plan.subType === SubscriptionType.PersonalSilver
      ) {
        return
      }
      if (variant === PricingPlansExperimentVariant.AnnualOnly) {
        plans.push({
          ...plan,
          monthlyPrice: '',
        })
      } else if (
        variant === PricingPlansExperimentVariant.PlatinumOnly ||
        (isAppUsing('personalPricingPlansExperimentV2') &&
          variant === PricingPlansExperimentVariant.PlatinumSilverAnnualOnly)
      ) {
        // Only show Free, Silver, and Platinum plans to native users
        if (plan.subType === SubscriptionType.PersonalGold) {
          return
        }
        plans.push(plan)
      } else {
        // variant === PricingPlansExperimentVariant.Control
        plans.push(plan)
      }
    })

    return plans
  }

  return {
    // State
    currencySign,
    schoolCurrencySign,

    // Getters
    freePlans,
    homePlans,
    businessPlans,
    educationPlans,
    libraryCreateClassroomPlans,
    libraryCreateTeamPlans,
    accountSetupPersonalPlans,
    accountSetupStudentPlans,
    accountSetupTeacherAndStaffPlans,
    accountSetupBusinessPlans,

    // Actions
    initializeState,
    getHomePlansByVariant,
  }
})
