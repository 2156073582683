/**
 * @file Helpers for plans pinia store
 * */

import { __ } from '@@/bits/intl'
import { SubscriptionFilter } from '@@/bits/landside_helper'
import { FileSizeUnit } from '@@/bits/units'
import { getDemoUrl } from '@@/bits/url'

export interface PlanPricing {
  annualPrice: number
  monthlyPrice?: number
  tier: string
  annualSavingsPercentage?: number
}

export enum SubscriptionType {
  Free,
  PersonalSilver,
  PersonalGold,
  PersonalPlatinum,
  Team,
  School,
  Classroom,
}

export enum PlanType {
  Neon = 'neon',
  Silver = 'silver',
  Gold = 'gold',
  Platinum = 'platinum',
}

export const teamGoldSchedulePrices = {
  monthlyPrice: '14.99',
  annualPrice: '149.99',
}

export interface SubscriptionPlan {
  name?: string
  subType: SubscriptionType
  filterType?: SubscriptionFilter
  planType?: PlanType
  darkImageUrl: string
  lightImageUrl: string
  description: string
  advertisedWallsQuota?: number
  loggedOutSolidButtonText?: string
  loggedOutSolidButtonHref?: string
  loggedInSolidButtonText?: string
  loggedInSolidButtonHref?: string
  loggedOutClearButtonText?: string
  loggedOutClearButtonHref?: string
  loggedInClearButtonText?: string
  loggedInClearButtonHref?: string
  userQuota?: number
  advertisedFileSizeQuota: string
  advertisedFileSizeQuotaUnit: FileSizeUnit
  advertisedVideoDurationQuota?: number
  advertisedAudioDurationQuota?: number
  monthlyPrice?: string
  annualPrice: string
  studentQuota?: number
  annualSavingsPercentage?: number
}

export const freeSubscription: SubscriptionPlan[] = [
  {
    subType: SubscriptionType.Free,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/free_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/free_plan_light.webp`,
    description: __('For trying out Padlet, no credit card required.'),
    userQuota: 1,
    advertisedWallsQuota: 3,
    loggedOutSolidButtonText: __('Sign up'),
    loggedOutSolidButtonHref: '/auth/signup',
    loggedInSolidButtonText: __('Go to billing'),
    loggedInSolidButtonHref: '/dashboard/settings/billing',
    advertisedFileSizeQuota: '20',
    advertisedFileSizeQuotaUnit: FileSizeUnit.MB,
    advertisedVideoDurationQuota: 2,
    advertisedAudioDurationQuota: 5,
    monthlyPrice: '0',
    annualPrice: '0',
  },
]

export const homeSubscriptions: SubscriptionPlan[] = [
  {
    subType: SubscriptionType.PersonalSilver,
    planType: PlanType.Silver,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_light.webp`,
    description: __('For the regular user who wants more.'),
    userQuota: 1,
    loggedOutSolidButtonText: __('Sign up'),
    loggedOutSolidButtonHref: '/auth/signup',
    loggedInSolidButtonText: __('Go to billing'),
    loggedInSolidButtonHref: '/dashboard/settings/billing',
    advertisedWallsQuota: 10,
    advertisedFileSizeQuota: '100',
    advertisedFileSizeQuotaUnit: FileSizeUnit.MB,
    advertisedVideoDurationQuota: 5,
    advertisedAudioDurationQuota: 10,
    annualPrice: '49.99',
  },
  {
    subType: SubscriptionType.PersonalGold,
    planType: PlanType.Gold,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_light.webp`,
    description: __('For the regular user who wants more.'),
    userQuota: 1,
    loggedOutSolidButtonText: __('Sign up'),
    loggedOutSolidButtonHref: '/auth/signup',
    loggedInSolidButtonText: __('Go to billing'),
    loggedInSolidButtonHref: '/dashboard/settings/billing',
    advertisedWallsQuota: 20,
    advertisedFileSizeQuota: '100',
    advertisedFileSizeQuotaUnit: FileSizeUnit.MB,
    advertisedVideoDurationQuota: 5,
    advertisedAudioDurationQuota: 10,
    monthlyPrice: '6.99',
    annualPrice: '69.99',
  },
  {
    subType: SubscriptionType.PersonalPlatinum,
    planType: PlanType.Platinum,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_light.webp`,
    description: __('For the power user who wants no padlet limits.'),
    userQuota: 1,
    loggedOutSolidButtonText: __('Sign up'),
    loggedOutSolidButtonHref: '/auth/signup',
    loggedInSolidButtonText: __('Go to billing'),
    loggedInSolidButtonHref: '/dashboard/settings/billing',
    advertisedFileSizeQuota: '500',
    advertisedFileSizeQuotaUnit: FileSizeUnit.MB,
    advertisedVideoDurationQuota: 15,
    advertisedAudioDurationQuota: 30,
    monthlyPrice: '9.99',
    annualPrice: '99.99',
  },
]

export const businessSubscriptions: SubscriptionPlan[] = [
  {
    subType: SubscriptionType.Team,
    planType: PlanType.Gold,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/business_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/business_plan_light.webp`,
    description: __('For teams working together.'),
    loggedOutSolidButtonText: __('Start trial'),
    loggedOutSolidButtonHref: '/account/create/team',
    loggedInSolidButtonText: __('Create team'),
    loggedInSolidButtonHref: '/account/create/team',
    loggedOutClearButtonText: __('Subscribe'),
    loggedOutClearButtonHref: '/account/create/team?subscribe=1',
    userQuota: 50,
    advertisedFileSizeQuota: '1',
    advertisedFileSizeQuotaUnit: FileSizeUnit.GB,
    advertisedVideoDurationQuota: 30,
    advertisedAudioDurationQuota: 60,
    monthlyPrice: teamGoldSchedulePrices.monthlyPrice,
    annualPrice: teamGoldSchedulePrices.annualPrice,
  },
]

export const educationSubscriptions: SubscriptionPlan[] = [
  {
    name: __('Classroom'),
    subType: SubscriptionType.Classroom,
    planType: PlanType.Gold,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/education_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/education_plan_light.webp`,
    description: __('For classroom collaboration and sharing.'),
    loggedOutSolidButtonText: __('Start trial'),
    loggedOutSolidButtonHref: '/account/create/classroom',
    loggedInSolidButtonText: __('Create classroom'),
    loggedInSolidButtonHref: '/account/create/classroom',
    loggedOutClearButtonText: __('Subscribe'),
    loggedOutClearButtonHref: '/account/create/classroom?subscribe=1',
    advertisedFileSizeQuota: '1',
    advertisedFileSizeQuotaUnit: FileSizeUnit.GB,
    advertisedVideoDurationQuota: 30,
    advertisedAudioDurationQuota: 60,
    userQuota: 2,
    studentQuota: 200,
    annualPrice: '0',
  },
  {
    subType: SubscriptionType.School,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/education_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/education_plan_light.webp`,
    description: __('For school and district-wide deployments.'),
    monthlyPrice: '0',
    annualPrice: '1,000',
    loggedOutSolidButtonText: __('Get a quote'),
    loggedOutSolidButtonHref: '/premium/backpack/quotation',
    loggedInSolidButtonText: __('Get a quote'),
    loggedInSolidButtonHref: '/premium/backpack/quotation',
    loggedOutClearButtonText: __('Request demo'),
    loggedOutClearButtonHref: getDemoUrl('external', {
      pageName: 'subscriptions',
      linkLocation: 'school_section_unregistered',
    }),
    loggedInClearButtonText: __('Request demo'),
    loggedInClearButtonHref: getDemoUrl('external', {
      pageName: 'subscriptions',
      linkLocation: 'school_section_registered',
    }),
    advertisedFileSizeQuota: '1',
    advertisedFileSizeQuotaUnit: FileSizeUnit.GB,
    advertisedVideoDurationQuota: 30,
    advertisedAudioDurationQuota: 60,
    planType: PlanType.Platinum,
  },
]

export const libraryCreateClassroomSubscriptions = [
  {
    name: __('Classroom'),
    subType: SubscriptionType.Classroom,
    planType: PlanType.Gold,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/education_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/education_plan_light.webp`,
    description: __('For classroom collaboration and sharing.'),
    loggedOutSolidButtonText: __('Start trial'),
    loggedOutSolidButtonHref: '/account/create/classroom',
    loggedInSolidButtonText: __('Start trial'),
    loggedInSolidButtonHref: '/account/create/classroom',
    loggedInClearButtonText: __('Subscribe'),
    loggedInClearButtonHref: '/account/create/classroom?subscribe=1',
    advertisedFileSizeQuota: '1',
    advertisedFileSizeQuotaUnit: FileSizeUnit.GB,
    advertisedVideoDurationQuota: 30,
    advertisedAudioDurationQuota: 60,
    userQuota: 2,
    studentQuota: 200,
    annualPrice: '199.99',
    monthlyPrice: '0',
  },
]

export const libraryCreateTeamSubscriptions = [
  {
    subType: SubscriptionType.Team,
    planType: PlanType.Gold,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/business_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/business_plan_light.webp`,
    description: __('For teams working together.'),
    loggedOutSolidButtonText: __('Start trial'),
    loggedOutSolidButtonHref: '/account/create/team',
    loggedInSolidButtonText: __('Start trial'),
    loggedInSolidButtonHref: '/account/create/team',
    loggedInClearButtonText: __('Subscribe'),
    loggedInClearButtonHref: '/account/create/team?subscribe=1',
    userQuota: 50,
    advertisedFileSizeQuota: '1',
    advertisedFileSizeQuotaUnit: FileSizeUnit.GB,
    advertisedVideoDurationQuota: 30,
    advertisedAudioDurationQuota: 60,
    monthlyPrice: teamGoldSchedulePrices.monthlyPrice,
    annualPrice: teamGoldSchedulePrices.annualPrice,
  },
]

// TODO: Rename winning variant and remove loser variant
export const accountSetupPersonalSubscriptions = [
  {
    name: __('Free'),
    subType: SubscriptionType.Free,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/free_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/free_plan_light.webp`,
    description: __('For a single user.'),
    userQuota: 1,
    advertisedWallsQuota: 3,
    loggedInSolidButtonText: __('Get started'),
    loggedInSolidButtonHref: '/memberships/welcome',
    advertisedFileSizeQuota: '20',
    advertisedFileSizeQuotaUnit: FileSizeUnit.MB,
    advertisedVideoDurationQuota: 2,
    advertisedAudioDurationQuota: 5,
    monthlyPrice: '0',
    annualPrice: '0',
  },
  {
    name: __('Silver'),
    subType: SubscriptionType.PersonalSilver,
    filterType: SubscriptionFilter.HOME,
    planType: PlanType.Silver,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_light.webp`,
    description: __('For a single user.'),
    userQuota: 1,
    loggedInSolidButtonText: __('Subscribe'),
    loggedInSolidButtonHref: '/premium/upgrade/new_signup',
    advertisedWallsQuota: 10,
    advertisedFileSizeQuota: '100',
    advertisedFileSizeQuotaUnit: FileSizeUnit.MB,
    advertisedVideoDurationQuota: 5,
    advertisedAudioDurationQuota: 10,
    annualPrice: '49.99',
  },
  {
    name: __('Gold'),
    subType: SubscriptionType.PersonalGold,
    filterType: SubscriptionFilter.HOME,
    planType: PlanType.Gold,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_light.webp`,
    description: __('For a single user.'),
    userQuota: 1,
    loggedInSolidButtonText: __('Subscribe'),
    loggedInSolidButtonHref: '/premium/upgrade/new_signup#gold',
    advertisedWallsQuota: 20,
    advertisedFileSizeQuota: '100',
    advertisedFileSizeQuotaUnit: FileSizeUnit.MB,
    advertisedVideoDurationQuota: 5,
    advertisedAudioDurationQuota: 10,
    monthlyPrice: '6.99',
    annualPrice: '69.99',
  },
  {
    name: __('Platinum'),
    subType: SubscriptionType.PersonalPlatinum,
    filterType: SubscriptionFilter.HOME,
    planType: PlanType.Platinum,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_light.webp`,
    description: __('For a single user.'),
    userQuota: 1,
    loggedInSolidButtonText: __('Subscribe'),
    loggedInSolidButtonHref: '/premium/upgrade/new_signup#platinum',
    advertisedFileSizeQuota: '500',
    advertisedFileSizeQuotaUnit: FileSizeUnit.MB,
    advertisedVideoDurationQuota: 15,
    advertisedAudioDurationQuota: 30,
    monthlyPrice: '9.99',
    annualPrice: '99.99',
  },
  {
    name: __('Team'),
    subType: SubscriptionType.Team,
    filterType: SubscriptionFilter.BUSINESS,
    planType: PlanType.Gold,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/business_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/business_plan_light.webp`,
    description: __('For teams working together.'),
    loggedInSolidButtonText: __('Start trial'),
    loggedInSolidButtonHref: '/account/create/team',
    loggedInClearButtonText: __('Subscribe'),
    loggedInClearButtonHref: '/account/create/team?subscribe=1',
    userQuota: 50,
    advertisedFileSizeQuota: '1',
    advertisedFileSizeQuotaUnit: FileSizeUnit.GB,
    advertisedVideoDurationQuota: 30,
    advertisedAudioDurationQuota: 60,
    monthlyPrice: teamGoldSchedulePrices.monthlyPrice,
    annualPrice: teamGoldSchedulePrices.annualPrice,
  },
]

export const accountSetupStudentSubscriptions = [
  {
    name: __('Free'),
    subType: SubscriptionType.Free,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/free_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/free_plan_light.webp`,
    description: __('For a single user.'),
    userQuota: 1,
    advertisedWallsQuota: 3,
    loggedInSolidButtonText: __('Get started'),
    loggedInSolidButtonHref: '/memberships/welcome',
    advertisedFileSizeQuota: '20',
    advertisedFileSizeQuotaUnit: FileSizeUnit.MB,
    advertisedVideoDurationQuota: 2,
    advertisedAudioDurationQuota: 5,
    monthlyPrice: '0',
    annualPrice: '0',
  },
  {
    name: __('Silver'),
    subType: SubscriptionType.PersonalSilver,
    planType: PlanType.Silver,
    filterType: SubscriptionFilter.HOME,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_light.webp`,
    description: __('For a single user.'),
    userQuota: 1,
    loggedInSolidButtonText: __('Subscribe'),
    loggedInSolidButtonHref: '/premium/upgrade/new_signup',
    advertisedWallsQuota: 10,
    advertisedFileSizeQuota: '100',
    advertisedFileSizeQuotaUnit: FileSizeUnit.MB,
    advertisedVideoDurationQuota: 5,
    advertisedAudioDurationQuota: 10,
    annualPrice: '49.99',
  },
  {
    name: __('Gold'),
    subType: SubscriptionType.PersonalGold,
    planType: PlanType.Gold,
    filterType: SubscriptionFilter.HOME,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_light.webp`,
    description: __('For a single user.'),
    userQuota: 1,
    loggedInSolidButtonText: __('Subscribe'),
    loggedInSolidButtonHref: '/premium/upgrade/new_signup#gold',
    advertisedWallsQuota: 20,
    advertisedFileSizeQuota: '100',
    advertisedFileSizeQuotaUnit: FileSizeUnit.MB,
    advertisedVideoDurationQuota: 5,
    advertisedAudioDurationQuota: 10,
    monthlyPrice: '6.99',
    annualPrice: '69.99',
  },
  {
    name: __('Platinum'),
    subType: SubscriptionType.PersonalPlatinum,
    planType: PlanType.Platinum,
    filterType: SubscriptionFilter.HOME,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_light.webp`,
    description: __('For a single user.'),
    userQuota: 1,
    loggedInSolidButtonText: __('Subscribe'),
    loggedInSolidButtonHref: '/premium/upgrade/new_signup#platinum',
    advertisedFileSizeQuota: '500',
    advertisedFileSizeQuotaUnit: FileSizeUnit.MB,
    advertisedVideoDurationQuota: 15,
    advertisedAudioDurationQuota: 30,
    monthlyPrice: '9.99',
    annualPrice: '99.99',
  },
]

export const accountSetupTeacherAndStaffSubscriptions = [
  {
    name: __('Free'),
    subType: SubscriptionType.Free,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/free_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/free_plan_light.webp`,
    description: __('For a single user.'),
    userQuota: 1,
    advertisedWallsQuota: 3,
    loggedInSolidButtonText: __('Get started'),
    loggedInSolidButtonHref: '/memberships/welcome',
    advertisedFileSizeQuota: '20',
    advertisedFileSizeQuotaUnit: FileSizeUnit.MB,
    advertisedVideoDurationQuota: 2,
    advertisedAudioDurationQuota: 5,
    monthlyPrice: '0',
    annualPrice: '0',
  },
  {
    name: __('Platinum'),
    subType: SubscriptionType.PersonalPlatinum,
    planType: PlanType.Platinum,
    filterType: SubscriptionFilter.HOME,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_light.webp`,
    description: __('For a single user.'),
    userQuota: 1,
    loggedInSolidButtonText: __('Subscribe'),
    loggedInSolidButtonHref: '/premium/upgrade/new_signup#platinum',
    advertisedFileSizeQuota: '500',
    advertisedFileSizeQuotaUnit: FileSizeUnit.MB,
    advertisedVideoDurationQuota: 15,
    advertisedAudioDurationQuota: 30,
    monthlyPrice: '9.99',
    annualPrice: '99.99',
  },
  {
    name: __('Classroom'),
    subType: SubscriptionType.Classroom,
    planType: PlanType.Gold,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/education_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/education_plan_light.webp`,
    description: __('For classroom collaboration and sharing.'),
    loggedInSolidButtonText: __('Start trial'),
    loggedInSolidButtonHref: '/account/create/classroom',
    loggedInClearButtonText: __('Subscribe'),
    loggedInClearButtonHref: '/account/create/classroom?subscribe=1',
    advertisedFileSizeQuota: '1',
    advertisedFileSizeQuotaUnit: FileSizeUnit.GB,
    advertisedVideoDurationQuota: 30,
    advertisedAudioDurationQuota: 60,
    userQuota: 2,
    studentQuota: 200,
    annualPrice: '0',
  },
  {
    name: __('Team'),
    subType: SubscriptionType.Team,
    planType: PlanType.Gold,
    filterType: SubscriptionFilter.BUSINESS,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/business_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/business_plan_light.webp`,
    description: __('For teams working together.'),
    loggedInSolidButtonText: __('Start trial'),
    loggedInSolidButtonHref: '/account/create/team',
    loggedInClearButtonText: __('Subscribe'),
    loggedInClearButtonHref: '/account/create/team?subscribe=1',
    userQuota: 50,
    advertisedFileSizeQuota: '1',
    advertisedFileSizeQuotaUnit: FileSizeUnit.GB,
    advertisedVideoDurationQuota: 30,
    advertisedAudioDurationQuota: 60,
    monthlyPrice: teamGoldSchedulePrices.monthlyPrice,
    annualPrice: teamGoldSchedulePrices.annualPrice,
  },
]

export const accountSetupBusinessSubscriptions = [
  {
    name: __('Free'),
    subType: SubscriptionType.Free,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/free_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/free_plan_light.webp`,
    description: __('For a single user.'),
    userQuota: 1,
    advertisedWallsQuota: 3,
    loggedInSolidButtonText: __('Get started'),
    loggedInSolidButtonHref: '/memberships/welcome',
    advertisedFileSizeQuota: '20',
    advertisedFileSizeQuotaUnit: FileSizeUnit.MB,
    advertisedVideoDurationQuota: 2,
    advertisedAudioDurationQuota: 5,
    monthlyPrice: '0',
    annualPrice: '0',
  },
  {
    name: __('Platinum'),
    subType: SubscriptionType.PersonalPlatinum,
    planType: PlanType.Platinum,
    filterType: SubscriptionFilter.HOME,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/personal_plan_light.webp`,
    description: __('For a single user.'),
    userQuota: 1,
    loggedInSolidButtonText: __('Subscribe'),
    loggedInSolidButtonHref: '/premium/upgrade/new_signup#platinum',
    advertisedFileSizeQuota: '500',
    advertisedFileSizeQuotaUnit: FileSizeUnit.MB,
    advertisedVideoDurationQuota: 15,
    advertisedAudioDurationQuota: 30,
    monthlyPrice: '9.99',
    annualPrice: '99.99',
  },
  {
    name: __('Team'),
    subType: SubscriptionType.Team,
    planType: PlanType.Gold,
    filterType: SubscriptionFilter.BUSINESS,
    darkImageUrl: `https://padlet.net/subscriptions/v0_2/business_plan_dark.webp`,
    lightImageUrl: `https://padlet.net/subscriptions/v0_2/business_plan_light.webp`,
    description: __('For teams working together.'),
    loggedInSolidButtonText: __('Start trial'),
    loggedInSolidButtonHref: '/account/create/team',
    loggedInClearButtonText: __('Subscribe'),
    loggedInClearButtonHref: '/account/create/team?subscribe=1',
    userQuota: 50,
    advertisedFileSizeQuota: '1',
    advertisedFileSizeQuotaUnit: FileSizeUnit.GB,
    advertisedVideoDurationQuota: 30,
    advertisedAudioDurationQuota: 60,
    monthlyPrice: teamGoldSchedulePrices.monthlyPrice,
    annualPrice: teamGoldSchedulePrices.annualPrice,
  },
]

// Nests each item into an array
// [a, b, c, d] -> [[a], [b], [c], [d]]
export function nestPlansArray(plans: SubscriptionPlan[]): SubscriptionPlan[][] {
  return plans.map((i) => [i])
}

export function updateSubscriptionsWithPrice(
  subscriptions: SubscriptionPlan[],
  prices: PlanPricing[],
  currencySign: string,
): SubscriptionPlan[] {
  return subscriptions.map((subscription) => {
    const price = prices.find((price) => price.tier.toLowerCase() === subscription.planType?.toLowerCase())

    if (price !== undefined) {
      const updatedSubscription = {
        ...subscription,
        annualPrice: `${currencySign}${price.annualPrice.toLocaleString()}`,
      }
      if (price.monthlyPrice !== undefined) {
        updatedSubscription.monthlyPrice = `${currencySign}${price.monthlyPrice.toLocaleString()}`
        updatedSubscription.annualSavingsPercentage = price.annualSavingsPercentage
      }
      return updatedSubscription
    }

    const updatedSubscription = {
      ...subscription,
      annualPrice: `${currencySign}${subscription.annualPrice}`,
    }
    if (subscription.monthlyPrice !== undefined) {
      updatedSubscription.monthlyPrice = `${currencySign}${subscription.monthlyPrice}`
    }

    return updatedSubscription
  })
}

export function singleLicenseifyClassroomSubscription(subscription: SubscriptionPlan): SubscriptionPlan {
  return {
    ...subscription,
    userQuota: 1,
    studentQuota: 100,
  }
}
